@import './variables.scss';


button.buttonTertiary.p-element.p-button.p-component {
  background: var(--master-color-tertiary-shade);
  border-color: var(--master-color-tertiary);
  color: var(--master-light-contrast);
}
button.buttonTertiary.p-element.p-button.p-component:hover {
  background: var(--master-color-tertiary);
  border-color: var(--master-color-tertiary-tint);
}
button.buttonTertiary.p-element.p-button.p-component:focus {
  background: var(--master-color-tertiary-tint);
  border-color: var(--master-light-contrast);
}





.p-button:enabled, .p-selectbutton .p-button.p-highlight, .p-togglebutton.p-button.p-highlight {
  background: var(--master-color-secondary-shade);
  border: 1px solid var(--master-color-secondary);
}
.p-button:enabled:hover, .p-selectbutton .p-button.p-highlight:hover, .p-togglebutton.p-button.p-highlight:hover {
  background: var(--master-color-secondary);
  border: 1px solid var(--master-color-secondary-tint);
}
.p-button:enabled:focus, .p-selectbutton .p-button.p-highlight:focus, .p-togglebutton.p-button.p-highlight:focus {
  background: var(--master-color-secondary-tint);
}

.p-selectbutton .p-button-label .ng-star-inserted span {
// .p-selectbutton .p-button span {
  font-weight: bolder;
}


textarea:focus, input:focus, .p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.6px var(--master-color-secondary-tint);
}

.p-inputtext:enabled:focus {
  border-color: var(--master-color-secondary-tint);
}

.ui-inputgroup {
  display: flex;

  .p-inputtext {
    border-radius: 0px 3px 3px 0px;
  }
}

.p-dropdown, .p-inputtext:enabled {
  border: 1px solid var(--master-color-secondary-shade);
}


.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
  background: var(--master-color-secondary-shade);
  border-color: var(--master-dark-contrast);
}
.p-accordion .p-accordion-header:not(.p-highlight):not(.p-disabled):hover .p-accordion-header-link {
  color: var(--master-color-secondary)
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight:hover .p-accordion-header-link {
  background: var(--master-color-secondary-tint);
  border-color: var(--master-dark-contrast);
}
// .p-accordion-header .p-highlight:hover {
// .p-accordion-header:hover {
//   background: var(--master-color-secondary-tint);
// }



// .p-inputtext:enabled {
//   border-color: var(--master-color-secondary-shade);
// }

.p-dropdown:not(.p-disabled):hover, .p-inputtext:enabled:hover {
  border-color: var(--master-color-secondary-tint);
}





.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: var(--master-color-secondary-shade);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:hover {
  background: var(--master-color-secondary);
}
.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight:focus {
  background: var(--master-color-secondary-tint);
}


p-multiselect {
  .p-multiselect.p-component {
    border: 1px solid var(--master-color-secondary-shade);
  }
}





.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: var(--master-color-secondary-shade);
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight:hover {
  background: var(--master-color-secondary-tint);
}

.p-checkbox .p-checkbox-box.p-highlight {
  background: var(--master-color-secondary);
}
