@import './variables.scss';

html {
  min-height: 100%;
}

body {
  // background: var(--master-color-primary-gradient);
}

ion-content {
  .xsmall, .small {
    padding: .5rem;
  }
  .small {
    padding: 1rem;
  }
  .medium {
    padding: 3rem;
  }
  .large, .xlarge {
    padding: 6rem;
  }

  main {
    min-height: 100%;
  }
  
}

app-header {
  grid-area: H;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
}




section#pc {
  height: 100%;
  width: 100%;
  overflow: overlay;
}

hr {
  border-top: 1px solid var(--master-color-secondary);
}

h1, h2, h3, h4, h5, h6 {
  color: #f5e3bb
}
