
// @import './master-variables.scss';
// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {

  --master-dark-contrast: #202020;
  --master-dark-contrast-rbg: 0,0,0;
  --master-color-primary-gradient: linear-gradient(to bottom, #171717 0%, #000000 100%);
  --master-light-contrast: #FFFFFF;
  --master-light-contrast-rgb: 255,255,255;

  --master-color-primary: #9B2F30;
  --master-color-primary-rgb: 155,47,48;
  --master-color-primary-gradient: linear-gradient(to bottom, #8E0102 0%, #110000 100%);
  --master-color-primary-tint: #a54445;
  --master-color-primary-tint-gradient: linear-gradient(to bottom, #A54445 0%, #532223 100%);
  --master-color-primary-shade: #88292a;
  --master-color-primary-shade-gradient: linear-gradient(to bottom, #88292A 0%, #441515 100%);
  --master-color-primary-contrast: var(--master-light-contrast);
  --master-color-primary-contrast-rgb: var(--master-light-contrast-rgb);

  --master-color-secondary: #ECC195;
  --master-color-secondary-rgb: 236,193,142;
  --master-color-secondary-gradient: linear-gradient(to bottom, #ECC195 50%, #76614B 100%);
  --master-color-secondary-tint: #FFE0C1;
  --master-color-secondary-tint-gradient: linear-gradient(to bottom, #FFE0C1 0%, #807061 100%);
  --master-color-secondary-shade: #CEA174;
  --master-color-secondary-shade-gradient: linear-gradient(to bottom, #CEA174 0%, #67513A 100%);
  --master-color-secondary-contrast: var(--master-dark-contrast);
  --master-color-secondary-contrast-rgb: var(--master-dark-contrast-rbg);

  --master-color-tertiary: #3C7773;
  --master-color-tertiary-rgb: 60,119,115;
  --master-color-tertiary-gradient: linear-gradient(to bottom, #3C7773 0%, #1E3C3A 100%);
  --master-color-tertiary-tint: #488D88;
  --master-color-tertiary-tint-gradient: linear-gradient(to bottom, #488D88 0%, #244744 100%);
  --master-color-tertiary-shade: #2D615D;
  --master-color-tertiary-shade-gradient: linear-gradient(to bottom, #2D615D 0%, #091313 100%);
  --master-color-tertiary-contrast: var(--master-light-contrast);
  --master-color-tertiary-contrast-rgb: var(--master-light-contrast-rgb);
  
  --master-color-success: #436D49;
  --master-color-success-rgb: 67,109,73;
  --master-color-success-gradient: linear-gradient(to bottom, #436D49 0%, #223725 100%);
  --master-color-success-tint: #5A8D61;
  --master-color-success-tint-gradient: linear-gradient(to bottom, #5A8D61 0%, #2D4731 100%);
  --master-color-success-shade: #26462A;
  --master-color-success-shade-gradient: linear-gradient(to bottom, #26462A 0%, #080E08 100%);
  --master-color-success-contrast: var(--master-light-contrast);
  --master-color-success-contrast-rgb: var(--master-light-contrast-rgb);

  --master-color-warning: #F37444;
  --master-color-warning-rgb: 243,116,68;
  --master-color-warning-gradient: linear-gradient(to bottom, #F37444 0%, #7A3A22 100%);
  --master-color-warning-tint:#F99771;
  --master-color-warning-tint-gradient: linear-gradient(to bottom, #F99771 0%, #7D4C39 100%);
  --master-color-warning-shade: #D65929;
  --master-color-warning-shade-gradient: linear-gradient(to bottom, #D65929 0%, #6B2D15 100%);
  --master-color-warning-contrast: var(--master-dark-contrast);
  --master-color-warning-contrast-rgb: var(--master-dark-contrast-rbg);

  --master-color-error: #B91F1F;
  --master-color-error-rgb: 185,31,31;
  --master-color-error-gradient: linear-gradient(to bottom, #B91F1F 0%, #5D1010 100%);
  --master-color-error-tint: #D64E4E;
  --master-color-error-tint-gradient: linear-gradient(to bottom, #D64E4E 0%, #6B2727 100%);
  --master-color-error-shade: #981212;
  --master-color-error-shade-gradient: linear-gradient(to bottom, #981212 0%, #4C0909 100%);
  --master-color-error-contrast: #CFC252;
  --master-color-error-contrast-rgb: 207,194,82;

  --master-color-dark: #101010;
  --master-color-dark-rgb: 34,36,40;
  --master-color-dark-gradient: linear-gradient(to bottom, #101010 0%, #070707 100%);
  --master-color-dark-tint: #383A3E;
  --master-color-dark-shade: #1E2023;
  --master-color-dark-contrast: var(--master-light-contrast);
  --master-color-dark-contrast-rgb: var(--master-light-contrast-rgb);

  --master-color-medium: #989AA2;
  --master-color-medium-rgb: 152,154,162;
  --master-color-medium-gradient: linear-gradient(to bottom, #989AA2 0%, #4C4D51 100%);
  --master-color-medium-tint: #a2a4ab;
  --master-color-medium-shade: #86888f;
  --master-color-medium-contrast: var(--master-dark-contrast);
  --master-color-medium-contrast-rgb: var(--master-dark-contrast-rbg);

  --master-color-light: #F4F5F8;
  --master-color-light-rgb: 244,245,248;
  --master-color-light-gradient: linear-gradient(to bottom, #F4F5F8 0%, #7A7B7C 100%);
  --master-color-light-tint: #f5f6f9;
  --master-color-light-shade: #d7d8da;
  --master-color-light-contrast: var(--master-dark-contrast);
  --master-color-light-contrast-rgb: var(--master-dark-contrast-rbg);




  --ion-color-primary: var(--master-color-primary);
  --ion-color-primary-rgb: var(--master-color-primary-rgb);
  --ion-color-primary-contrast: var(--master-color-primary-contrast);
  --ion-color-primary-contrast-rgb: var(--master-color-primary-contrast-rgb);
  --ion-color-primary-shade: var(--master-color-primary-shade);
  --ion-color-primary-tint: var(--master-color-primary-tint);

  --ion-color-secondary: var(--master-color-secondary);
  --ion-color-secondary-rgb: var(--master-color-secondary-rgb);
  --ion-color-secondary-contrast: var(--master-color-secondary-contrast);
  --ion-color-secondary-contrast-rgb: var(--master-color-secondary-contrast-rgb);
  --ion-color-secondary-shade: var(--master-color-secondary-shade);
  --ion-color-secondary-tint: (--master-color-secondary-tint);

  --ion-color-tertiary: var(--master-color-tertiary);
  --ion-color-tertiary-rgb: var(--master-color-tertiary-rgb);
  --ion-color-tertiary-contrast: var(--master-color-tertiary-contrast);
  --ion-color-tertiary-contrast-rgb: var(--master-color-tertiary-contrast-rgb);
  --ion-color-tertiary-shade: var(--master-color-tertiary-shade);
  --ion-color-tertiary-tint: var(--master-color-tertiary-tint);

  --ion-color-success: var(--master-color-success);
  --ion-color-success-rgb: var(--master-color-success-rgb);
  --ion-color-success-contrast: var(--master-color-success-contrast);
  --ion-color-success-contrast-rgb: var(--master-color-success-contrast-rgb);
  --ion-color-success-shade: var(--master-color-success-shade);
  --ion-color-success-tint: var(--master-color-success-tint);

  --ion-color-warning: var(--master-color-warning);
  --ion-color-warning-rgb: var(--master-color-warning-rgb);
  --ion-color-warning-contrast: var(--master-color-warning-contrast);
  --ion-color-warning-contrast-rgb: var(--master-color-warning-contrast-rgb);
  --ion-color-warning-shade: var(--master-color-warning-shade);
  --ion-color-warning-tint: var(--master-color-warning-tint);

  --ion-color-danger: var(--master-color-danger);
  --ion-color-danger-rgb: var(--master-color-danger-rgb);
  --ion-color-danger-contrast: var(--master-color-danger-contrast);
  --ion-color-danger-contrast-rgb: var(--master-color-danger-rgb);
  --ion-color-danger-shade: var(--master-color-danger-shade);
  --ion-color-danger-tint: var(--master-color-danger-tint);

  --ion-color-dark: var(--master-color-dark);
  --ion-color-dark-rgb: var(--master-color-dark-rgb);
  --ion-color-dark-contrast: var(--master-color-dark-contrast);
  --ion-color-dark-contrast-rgb: var(--master-color-dark-contrast-rgb);
  --ion-color-dark-shade: var(--master-color-dark-shade);
  --ion-color-dark-tint: var(--master-color-dark-tint);

  --ion-color-medium: var(--master-color-medium);
  --ion-color-medium-rgb: var(--master-color-medium-rgb);
  --ion-color-medium-contrast: var(--master-color-medium-contrast);
  --ion-color-medium-contrast-rgb: var(--master-medium-contrast-rgb);
  --ion-color-medium-shade: var(--master-color-medium-shade);
  --ion-color-medium-tint: var(--master-color-medium-tint);

  --ion-color-light: var(--master-color-light);
  --ion-color-light-rgb: var(--master-color-light-rgb);
  --ion-color-light-contrast: var(--master-color-light-contrast);
  --ion-color-light-contrast-rgb: var(--master-color-light-rgb);
  --ion-color-light-shade: var(--master-color-light-shade);
  --ion-color-light-tint: var(--master-color-light-tint);

  // Added:
  // --ion-background-color: linear-gradient(to bottom, #8E0102 0%, #110000 100%);
  --ion-text-color: var(--ion-color-secondary)

}

ion-content {
  --background: transparent;
  // --background: var(--master-color-primary-gradient)
}

ion-item {
  --color: var(--ion-color-primary)
}

ion-item:hover {
  background-color: #eccd95;
}

ion-segment-button {
  --color: black;
}

ion-button.ion-color-secondary:hover {
  background-color: #eccd95;
  border-radius: 4px;
}