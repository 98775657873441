h1 {
  font-size: 30px
}

h2 {
  font-size: 26px
}

h3 {
  font-size: 23px
}

h4 {
  font-size: 20px
}

h5 {
  font-size: 18px;
}