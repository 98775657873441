/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";
@import "@ionic/angular/css/typography.css";
@import '@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "@ionic/angular/css/padding.css";
@import "@ionic/angular/css/float-elements.css";
@import "@ionic/angular/css/text-alignment.css";
@import "@ionic/angular/css/text-transformation.css";
@import "@ionic/angular/css/flex-utils.css";

/* primegn ui library for pc rendering */
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/primeng/resources/themes/luna-amber/theme.css';
@import './theme/destkop.theme.scss';
@import '../node_modules/primeng/resources/primeng.min.css';

// Ag-Grid
// @import "~ag-grid-community/dist/styles/ag-grid.css";
// @import "~ag-grid-community/dist/styles/ag-theme-balham.css";

@import 'ag-grid-community/styles/ag-grid.css';
@import 'ag-grid-community/styles/ag-theme-balham.css';


//Custom
@import './theme/variables.scss';
@import './theme/overrides.scss';
@import './theme/tags.scss';
@import './theme/classes.scss';
@import './theme/fonts.scss';

